import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})

    if(window.location.pathname == '/' ){
        smoothScroll('a[href*="#"]', {offset: 120})
    };

    $(".readmore-link").click( function(e) {
        // record if our text is expanded
        var isExpanded =  $(e.target).hasClass("expand");

        //close all open paragraphs
        $(".category-collection--wrapper.expand").removeClass("expand");
        $(".readmore-link.expand").removeClass("expand");

        // if target wasn't expand, then expand it
        if (!isExpanded){
          $( e.target ).parent( ".category-collection--wrapper" ).addClass( "expand" );
          $(e.target).addClass("expand");
        };

        if ($(e.target).text()=='Mehr anzeigen') {
            $(this).text('Weniger anzeigen');
          }
          else {
            $(this).text('Mehr anzeigen');
          }
      });

    // close burger menu if cklcik on id to show target
    $('.header-nav-list li a').click(function(){
        $(".header-nav-content").collapse('hide');
    });

    var selector = '.header-nav-content .header-nav-list li';
    $(selector).on('click', function(){
        $(selector).removeClass('current');
        $(this).addClass('current');
    });

})


 $(document).scroll(function () {
 var scroll = $(this).scrollTop()
 if ($(window).width() >= 1024 && scroll > 560) {
   $('.header-nav').addClass('fixed');
 } else {
   $('.header-nav').removeClass('fixed');
 };
})

var distance = $('.circle').offset().top +175;
$(window).scroll(function () {
    if ($(window).scrollTop() >= distance) {
        $('.circle').addClass("affix");
    } else {
        $('.circle').removeClass("affix");
    }
});

$(window).scroll(function () {
    var position = window.pageYOffset;
    $('.grid-content').each(function () {
      var target = $(this).offset().top;
      var id = $(this).attr('id');
      var navLinks = $('.second-level-sub-navigation li a');
      var navLinksHome = $('.header-nav-list li a');
      if (position >= target - 200 && id) {
        navLinks.removeClass('current');
        navLinksHome.removeClass('current');
        $('.second-level-sub-navigation li a[href*="#' + id + '"]').addClass('current');
        $('.header-nav-list li a[href*="#' + id + '"]').addClass('current');
        $('.header-nav-list li').removeClass('current');
      } else {
        $('.header-nav-list li current').removeClass('current');
      }
    });
 });
